"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnualScheduleTypes = void 0;
var AnnualScheduleTypes;
(function (AnnualScheduleTypes) {
    AnnualScheduleTypes["MassRenewalNoticesPostalOnly"] = "Mass Renewal Notices - Postal Only";
    AnnualScheduleTypes["MassRenewalNoticesAll"] = "Mass Renewal Notices - All";
    AnnualScheduleTypes["NonpaymentViolationNotices"] = "Nonpayment Violation Notices";
    AnnualScheduleTypes["FinalViolationNotices"] = "Final Violation Notices";
})(AnnualScheduleTypes = exports.AnnualScheduleTypes || (exports.AnnualScheduleTypes = {}));
