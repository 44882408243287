"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkingAsDate = exports.permitRenewalDateYear = void 0;
/**
 *
 * @param dateChanger - date on req.user object
 * @returns number - Date year (number) based on the permit renewal month. Returns NULL, if NODE_ENV is production and input is undefined or empty string
 */
exports.permitRenewalDateYear = function (dateChanger) {
    if (process.env.NODE_ENV !== "production" && dateChanger && dateChanger !== "") {
        var date = new Date(dateChanger);
        if (!isNaN(date.getTime())) {
            return date.getMonth() > 8 ? date.getFullYear() + 1 : date.getFullYear();
        }
    }
    return null;
};
exports.getWorkingAsDate = function (dateChanger) {
    if (process.env.NODE_ENV !== "production" && dateChanger && dateChanger !== "") {
        var dateChangerDate = new Date(dateChanger);
        if (!isNaN(dateChangerDate.getTime())) {
            return dateChangerDate;
        }
    }
    return new Date();
};
