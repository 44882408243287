"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.canRenewPermit = exports.isWithinRenewalPeriod = void 0;
var PermitStatus_1 = require("../models/enums/PermitStatus");
var dateChangerService = __importStar(require("../services/DateChangerService"));
//Workflow for renewals. Using year 2024 as an example.
//Sept 15th 2024, the renewal period begins.    
//Batch job sets the Renewal Date in the db to Jan 15th, 2025, and last day to renew March 5th (or closest business day)
//Any permits renewed in this time period have their expiration date set to Jan 15th 2026.    
//On Jan 15th 2025, a batch job runs to expire all Active permits where their expiration date is the Renewal Date (Jan 2025)
//On March 5th, 2025, renewals are no longer accepted via ODA and must be done on paper.     
//And on Sept 15th, 2025, the cycle repeats.
exports.isWithinRenewalPeriod = function (renewalDateRecords, dateChangerDate) {
    var renewalDate = new Date(renewalDateRecords.renewalDate);
    var earliestRenewalDate = new Date(renewalDate.getFullYear() - 1, 8, 15);
    var latestRenewalDate = new Date(renewalDateRecords.lastDateToRenewWithoutPetition);
    var now = dateChangerService.getWorkingAsDate(dateChangerDate);
    now.setHours(0, 0, 0, 0);
    return now >= earliestRenewalDate && now <= latestRenewalDate;
};
exports.canRenewPermit = function (permit, renewalDateRecords, dateChangerDate) {
    //Must attempt to make dates of these as they could be strings depending on where this was called from.    
    var renewalDate = new Date(renewalDateRecords.renewalDate);
    if (!exports.isWithinRenewalPeriod(renewalDateRecords, dateChangerDate)) {
        return false;
    }
    if (permit.status === PermitStatus_1.PermitStatus.Active) {
        //Active permits can be renewed as long as it's within the renewal period.
        return true;
    }
    else if (permit.status === PermitStatus_1.PermitStatus.Expired) {
        //If this permit is expired, but expired on the current renewal date, it is eligible.
        var expDate = new Date(permit.expirationDate);
        return expDate >= renewalDate;
    }
    return false;
};
